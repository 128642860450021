.logo {
    img {
        flex-shrink: 0;
        width: 100%;
        height: 28px;
    }
}

.menu-div {
    display: flex;
    justify-content: end;
}

.sell-button,
.sign-button {
    font-size: 15px;
    letter-spacing: .5px;
}

.form-control {
    text-align: center;
}


.has-search .form-control-feedback {
    z-index: 2;
    pointer-events: none;
    color: #aaa;
}

.tabs {
    color: #183A37;
}

.tabs {
    color: #6c757d;
}

.filters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 1rem;

    input, select {
        height: 40px;
    }
}

.dropdown-menu {
    z-index: 1100;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #FFA552;
}

.searchWrapper {
    .form-control {
        height: unset;
        padding: 0;
    }

    .search-input:focus {
        box-shadow: none;
    }
}

.filter-btn:hover {
    background-color: #F8F7F7;
    transition: all 0.4s ease-in-out;
}

.notification-count {
    font-size: 12px;
    border-radius: 50%;
    margin-bottom: 30px;
    font-weight: bolder;
    padding-bottom: 2px;
    margin-left: 12px;
    height: 20px;
    width: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.dropdown-count {
    margin-left: 5px !important;
}


// mobile menu styles

.mobile-menu {
    max-width: 100vw;
    height: 60px;
    box-shadow: 0 -5px 5px -5px #333;

    button,
    a {

        span,
        a {
            font-size: 11px;
        }

        color: black;
        border-top: 1px solid #E8E8E8;
        border-right: 1px solid #E8E8E8;
        background-color:white;
        width: 100%;
    }

    button:nth-child(3),
    a:nth-child(3) {
        border-top: 1px solid #E8E8E8;
    }

    button:first-child,
    a:first-child,
    button:last-child,
    a:last-child {
        span {
            font-size: 14px;
        }

        color: white;
        border: 0;
        background-color: #FF9839;
        min-width: max-content;
    }

    button:last-child,
    a:last-child {

        color: white;
        border: 0;
        background-color: #3C9CE6;
    }

}

.header-categories {
    height: 50px;
    z-index: 999;

    .height-setter {
        height: 50px;
    }

    #mobileLogo {
        display: flex;
        align-items: center;
    }
}

#collapseMobile {
    ul {
        list-style-type: none;

        li {
            width: 100%;
        }
    }
}

#language-dropdown {
    font-weight: 600;
    font-size: 14px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px;
    display: inline-flex;

    img {
        object-fit: cover;
        height: 20px;
        width: 30px;
        border-radius: 4px;
    }
    @media (min-width: 992px) {
        img {
            object-fit: cover;
            height: 25px;
            width: 35px;
            border-radius: 4px;
        }
    }
}

.dropdown-lang {
    min-width: auto !important;
    border: none;
    background: transparent;

    .dropdown-item {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px;
        border: none !important;
        display: inline-flex;
        margin-top: 5px;
        border-radius: 4px;
    }

    img {
        object-fit: cover;
        height: 20px;
        width: 30px;
        border-radius: 4px;
    }
    @media (min-width: 992px) {
        img {
            object-fit: cover;
            height: 25px;
            width: 35px;
            border-radius: 4px;
        }
    }
}



#searchForm {
    background: #F8F7F7;
    border-color: #F8F7F7;
    color: #828282;
    padding: 5px 15px 5px 15px;
    min-width: 300px;
}


#searchDesktop,
#searchMobile {
    background: transparent;
}

.header-btn {
    span {
        font-size: 14px;
    }
}

@media(min-width: 2000px) {
    header {
        .menu-div {
            justify-content: start;
        }
    }
}

.affilate-image-mobile {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

.mobile-menu-width {
    width: 50%;
    @media (min-width: 426px) {
        width: 30%;
    }
}

@media(max-width: 1600px) {
    .filters {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

}

@media(max-width: 1440px) {
    .filters {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

}

@media(max-width: 1250px) {
    .filters {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}


@media (min-width: 991px) and (max-width: 1250px) {

    a.why-bidpark {
        display: none;
    }
}

@media (min-width: 991px) and (max-width: 1100px) {

    .bonus-elements {
        display: none !important;
    }

}

@media(max-width: 991px) {
    #collapseFilters {
        position: absolute;
        height: calc(100vh - 50px);
        width: 100%;
        top: 50px;
        bottom: 0px;
        overflow: hidden;
        z-index: 50;
        background-color: #FAFAFA;
    }

    .filters-content {
        height: 100%;
    }

    .filters {
        grid-template-columns: 1fr 1fr 1fr;
        padding-bottom: 150px;
    }

    .mobile-apply-filters {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        height: 70px;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;

        button {
            width: 100%;
            height: 40px;
            color: white;
            font-weight: 600;
            text-align: center;
            border: none;
            border-radius: 4px;
            font-size: 14px !important;
        }
    }
}

@media(max-width: 767px) {
    #searchForm {
        min-width: auto;
    }

    .filters {
        grid-template-columns: 1fr 1fr;
    }
}

@media(max-width: 567px) {
    .logo {
        max-width: 100%;
    }
}

@media (max-width: 415px) {
    .filters {
        grid-template-columns: 1fr;
    }
}


@media (max-width: 368px) {
    .mobile-menu {
        a {
            span {
                font-size: 9px;
            }
        }

        a:first-child,
        a:last-child {
            span {
                font-size: 11px;
            }
        }
    }
}
